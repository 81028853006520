import './MainPage.css';
import React from 'react'
import Logo from './logo.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';





const LogoImage = () => {
    return (
        <img src={Logo} alt="" />
    )
}

export function AdminPage() {

    const [data, setData] = useState([]);
   

    useEffect(() => {
        axios
            .get("https://6681045256c2c76b495d4291.mockapi.io/tableAPR/1")
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
 


    return (

        <div className="main-mp">
            <div className="wrap-main-mp">

                <div className="header-mp">
                    <LogoImage />stake
                </div>

                <div className="header-text-mp">
                    ADMIN PANEL
                </div>

                <div className="center-mp">


                    <div className="block2">
                        Client ID:
                        <br />
                        {data.DocID}
                        <br />
                        <br />

                        Client emails:
                        <br />
                        {data.mail}

                        <br />
                        <br />
                        



                    </div>
        

                </div>





            </div>

        </div>

    )
} 