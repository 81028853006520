import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, useAccount } from 'wagmi'
import { config } from './config.ts'
import { Account } from './account.tsx'
import { WalletOptions2 } from './wallet-options.tsx'
import './WalletModal.css';


const queryClient = new QueryClient()

export function ConnectWallet() {
  const { isConnected } = useAccount()
  if (isConnected) return <Account />
  return <WalletOptions2 />
}

export function WalletAddr () {
  const { address } = useAccount()

  return (
  
      address
  
  )

}

export function GetWallet2 ()  {
  const { address } = useAccount()

  return (
    
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          
                {address}
                
        </QueryClientProvider>
      </WagmiProvider >
    )
 
}


export function WalletModal() {

  return (
    
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          
                <ConnectWallet />
                
        </QueryClientProvider>
      </WagmiProvider >
    )
 
}