import './CreateProductModal.css';
import { ReactComponent as IconClose } from './icon-close.svg';
import { useEffect, useState } from 'react';



export const CreateProductModal = ({ isOpen, onClose, login, data2 }) => {




    const [myData, setMyData] = useState({
        ETHEREUM: '',
        SOLANA: '',
        USDT: '',
        CARDANO: '',
        POLYGON: '',
        AXELAR: '',
        COSMOS: '',
        APTOS: '',
    })




    function submit(e) {
        e.preventDefault();

        fetch('https://6681045256c2c76b495d4291.mockapi.io/tableAPR/'+ data2.filter(filter=>filter.Main===login)[0].id, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            // Send your data in the request body as JSON
            body: JSON.stringify(myData)
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            // handle error
        }).then(task => {
            // do something with the new task
        }).catch(error => {
            // handle error
        })


        onClose(true)



    }



    return (
        isOpen && (
            <div className="product-modal">
                <div className="product-wrapper">
                    <div className="product-content">

                        <button className="modal-close-button" onClick={() => onClose()}>
                            <IconClose height={30} width={30} />
                        </button>
                        <form onSubmit={submit} className='form-create'>
                            <div className="b1">
                                Create your product

                            </div>

                            <table className="table-cr">
                                <thead>
                                    <th>
                                        Coin
                                    </th>
                                    <th>
                                        Base APR
                                    </th>
                                    <th>
                                        Product APR
                                    </th>


                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ETHEREUM</td>
                                        <td>82%</td>
                                        <td><input className="ProductAPR" name="ETHEREUM" onChange={e => setMyData({ ...myData, ETHEREUM: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>SOLANA</td>
                                        <td>94%</td>
                                        <td><input className="ProductAPR" name="SOLANA" onChange={e => setMyData({ ...myData, SOLANA: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>USDT</td>
                                        <td>86%</td>
                                        <td><input className="ProductAPR" name="USDT" onChange={e => setMyData({ ...myData, USDT: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>CARDANO</td>
                                        <td>49%</td>
                                        <td><input className="ProductAPR" name="CARDANO" onChange={e => setMyData({ ...myData, CARDANO: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>POLYGON</td>
                                        <td>37%</td>
                                        <td><input className="ProductAPR" name="POLYGON" onChange={e => setMyData({ ...myData, POLYGON: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>AXELAR</td>
                                        <td>53%</td>
                                        <td><input className="ProductAPR" name="AXELAR" onChange={e => setMyData({ ...myData, AXELAR: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>COSMOS</td>
                                        <td>44%</td>
                                        <td><input className="ProductAPR" name="COSMOS" onChange={e => setMyData({ ...myData, COSMOS: e.target.value })} required />%</td>
                                    </tr>
                                    <tr>
                                        <td>APTOS</td>
                                        <td>38%</td>
                                        <td><input className="ProductAPR" name="APTOS" onChange={e => setMyData({ ...myData, APTOS: e.target.value })} required />%</td>
                                    </tr>

                                </tbody>
                            </table>

                            <br />

                            <br />

                            <div className="b3">
                                <button className='btn2' type='submit'>CREATE</button>
                            </div>


                        </form>

                    </div>


                </div>
            </div>
        )
    )
}
