import './StkModal.css';
import { ReactComponent as IconClose } from './icon-close.svg';
import {ConnectWallet} from '../WalletModal/WalletModal.jsx'
import { MintSOL } from '../StakingModal/mint-nft.tsx'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, useAccount } from 'wagmi'
import { config } from './config.ts'

const queryClient = new QueryClient()

export const StkSOLmodal = ({ isOpen, onClose, login, apr }) => {

    return (
        isOpen && (
            <div className="stk-modal">
                <div className="stk-wrapper">
                    <div className="stk-content">
                        <button className="modal-close-button" onClick={() => onClose()}>
                            <IconClose height={30} width={30} />
                        </button>
                        <WagmiProvider config={config}>
                            <QueryClientProvider client={queryClient}>

                                <ConnectWallet />
                                <MintSOL login={login} apr ={apr}/>

                            </QueryClientProvider>
                        </WagmiProvider >
                    </div>
                </div>
            </div>
        )
    )
}