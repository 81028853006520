import './AccountMasterModal.css';
import { ReactComponent as IconClose } from './icon-close.svg';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { WagmiProvider, useAccount } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './config.ts'
import { GetWallet } from '../WalletModal/account.tsx'



export const AccountMasterModal = ({ isOpen, onClose, login, data, data2 }) => {

    const queryClient = new QueryClient()
    //   const [data, setData] = useState([]);
    //   const [data2, setData2] = useState([]);




    //  useEffect(() => {



    //     async function GetData() {

    //         const resp = await axios.get("https://6681045256c2c76b495d4291.mockapi.io/staking")
    //         setData(resp.data)

    //         const resp2 = await axios.get("https://6681045256c2c76b495d4291.mockapi.io/tableAPR")
    //         setData2(resp2.data)


    //     }


    //    GetData()


    // }, [isOpen]);






    // data2.filter(filter =>
    //    filter.Main === login
    // )[0].Client









    function GetStakingDataMain() {



        const { address } = useAccount()

        const filtered = data.filter(filter =>
            filter.UserWallet === address
        );

        const filtered2 = filtered.filter(filter =>
            filter.UserLogin === login
        );





        return (

            <table className='table-acc'>
                <thead>
                    <th>Coin</th>
                    <th>APR</th>
                    <th>Date</th>
                    <th>Amount</th>

                </thead>
                <tbody>
                    {filtered2.map((user) => (
                        <tr>

                            <td >{user.coin}</td>
                            <td> {user.BaseAPR}%</td>
                            <td> {user.date}</td>
                            <td> {user.amount}</td>
                        </tr>
                    ))}

                </tbody>
            </table>

        )

    }


    function GetStakingDataClient() {




        const filtered = data.filter(filter =>
            filter.UserLogin === data2.filter(filter =>
                filter.Main === login
            )[0].Client
        )

        return (

            <table className='table-acc'>
                <thead>
                    <th>Coin</th>
                    <th>APR</th>
                    <th>Date</th>
                    <th>Amount</th>

                </thead>
                <tbody>
                    {filtered.map((user) => (
                        <tr>

                            <td >{user.coin}</td>
                            <td> {user.ProfitAPR}%</td>
                            <td> {user.date}</td>
                            <td> {user.amount}</td>
                        </tr>
                    ))}

                </tbody>
            </table>

        )

    }


    return (
        isOpen && (
            <div className="account-modal">
                <div className="account-wrapper">
                    <div className="account-content">
                        <button className="modal-close-button" onClick={() => onClose()}>
                            <IconClose height={30} width={30} />
                        </button>
                        <div className="bl1">
                            Account &nbsp; {login}
                        </div>

                        <div className="bl2">
                            Your wallet connected:
                            <br />
                            <WagmiProvider config={config}>

                                <QueryClientProvider client={queryClient}>

                                    <GetWallet />

                                </QueryClientProvider>
                            </WagmiProvider >
                            <br />
                            Period: 30 days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className='btn7'>manual</button>

                        </div>
                        <WagmiProvider config={config}>
                            <QueryClientProvider client={queryClient}>

                                <GetStakingDataMain />

                            </QueryClientProvider>
                        </WagmiProvider >



                        <br />
                        <br />
                        <br />
                        <br />


                        <div className="bl1">
                            Product &nbsp; {data2.filter(filter =>
                                filter.Main === login
                            )[0].Client}
                        </div>

                        <GetStakingDataClient />
                        <br /><br />


                    </div>
                </div>
            </div>
        )
    )
}
