import './AccountMasterModal.css';
import { ReactComponent as IconClose } from './icon-close.svg';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { WagmiProvider, useAccount } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './config.ts'
import { GetWallet } from '../WalletModal/account.tsx'


export const AccountMasterModal = ({ isOpen, onClose, login, data }) => {

    const queryClient = new QueryClient()




    function GetStakingDataClient() {

        
        const { address } = useAccount()

        const filtered = data.filter(filter =>
            filter.UserWallet === address
        );

        const filtered2 = filtered.filter(filter =>
            filter.UserLogin === login
        );

        return (

            <table className='table-acc'>
                <thead>
                    <th>Coin</th>
                    <th>APR</th>
                    <th>Date</th>
                    <th>Amount</th>

                </thead>
                <tbody>
                    {filtered2.map((user) => (
                        <tr>

                            <td >{user.coin}</td>
                            <td> {user.ClientAPR}%</td>
                            <td> {user.date}</td>
                            <td> {user.amount}</td>
                        </tr>
                    ))}

                </tbody>
            </table>

        )

    }



    return (
        isOpen && (
            <div className="account-modal">
                <div className="account-wrapper">
                    <div className="account-content">
                        <button className="modal-close-button" onClick={() => onClose()}>
                            <IconClose height={30} width={30} />
                        </button>
                        <div className="bl1">
                            Account &nbsp; {login}
                        </div>
                        <div className="bl2">
                            Your wallet connected:
                            <br />
                            <WagmiProvider config={config}>

                                <QueryClientProvider client={queryClient}>

                                    <GetWallet />

                                </QueryClientProvider>
                            </WagmiProvider >
                            <br />
                            Period: 30 days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className='btn7'>manual</button>

                        </div>

                        <WagmiProvider config={config}>
                            <QueryClientProvider client={queryClient}>
                                <GetStakingDataClient />
                            </QueryClientProvider>
                        </WagmiProvider >

                        <br />
                        <br />


                    </div>
                </div>
            </div >
        )
    )
}
