import * as React from 'react'
import { Connector, useConnect } from 'wagmi'
import './WalletModal.css';



export function WalletOptions() {
  const { connectors, connect } = useConnect()

  return connectors.map((connector) => (
    <WalletOption
      key={connector.uid}
      connector={connector}
      onClick={() => connect({ connector })}
    />
  ))
}

export function WalletOptions2 () {

  return WalletOptions() [1]
}


function WalletOption({
  connector,
  onClick,
}: {
  connector: Connector
  onClick: () => void
}) 
{
  const [ready, setReady] = React.useState(false)

  React.useEffect(() => {
    ; (async () => {
      const provider = await connector.getProvider()
      setReady(!!provider)
    })()
  }, [connector])

  return (
    <button className='btn3' disabled={!ready} onClick={onClick}>
      CONNECT
    </button>
  )
}