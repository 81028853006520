import './MainPage.css';
import React from 'react'
import { useState, useEffect } from 'react';
import Logo from './logo.svg';
import { StkUSDTmodal } from './StkUSDTmodal'
import { StkSOLmodal } from './StkSOLmodal';
import { StkETHmodal } from './StkETHmodal';
import { AccountMasterModal } from './AccountMasterModal';
import { StkEmptymodal } from './StkEmptymodal';





const LogoImage = () => {
    return (
        <img src={Logo} alt="" />
    )
}

export function MainPageClient({ login, data, data2 }) {

    const [accountMasterModalIsOpen, setAccountMasterModalOpen] = useState(false);
    const [stkUSDTmodalIsOpen, setStkUSDTmodalOpen] = useState(false);
    const [stkSOLmodalIsOpen, setStkSOLmodalOpen] = useState(false);
    const [stkETHmodalIsOpen, setStkETHmodalOpen] = useState(false);
    const [stkEmptymodalIsOpen, setStkEmptymodalOpen] = useState(false);


    const filtered = data2.filter(filter =>
        filter.Client === login
    );



    return (

        <div className="main-mp">
            <div className="wrap-main-mp">

                <div className="header-mp">
                    <LogoImage />stake
                </div>
                <div className="button-block">
                    <button className='btn4' onClick={() => setAccountMasterModalOpen(true)}>Account</button>
                </div>
                <div className="header-text-mp">
                    STAKE ONE OF 8 CRYPTOCURRENCIES
                </div>

                <div className="center-mp">

                
                        <table className="table">
                            <thead>
                                <th>
                                    Coin
                                </th>
                                <th>
                                    APR
                                </th>
                                <th>
                                    30  days
                                </th>


                            </thead>
                            <tbody>
                                <tr>
                                    <td>ETHEREUM</td>
                                    <td>{filtered[0].ETHEREUM}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkETHmodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>SOLANA</td>
                                    <td>{filtered[0].SOLANA}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkSOLmodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>USDT</td>
                                    <td>{filtered[0].USDT}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkUSDTmodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>CARDANO</td>
                                    <td>{filtered[0].CARDANO}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkEmptymodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>POLYGON</td>
                                    <td>{filtered[0].POLYGON}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkEmptymodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>AXELAR</td>
                                    <td>{filtered[0].AXELAR}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkEmptymodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>COSMOS</td>
                                    <td>{filtered[0].COSMOS}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkEmptymodalOpen(true)}>Stake</button></td>
                                </tr>
                                <tr>
                                    <td>APTOS</td>
                                    <td>{filtered[0].APTOS}%</td>
                                    <td><button className='btn1-mp' onClick={() => setStkEmptymodalOpen(true)}>Stake</button></td>
                                </tr>

                            </tbody>
                        </table>
                    



                    <div className="header-text2-mp">
                        WHY PEOPLE STAKE THEIR CRYPTO WITH NEXOSTAKE
                    </div>

                    <div className="block2">
                        1.
                        <br />
                        TO ACCRUE MORE VALUE BY ENTERING WHAT WILL BECOME THE BACKBONE OF TOMORROW’S ECONOMY
                    </div>


                    <div className="block3">
                        2.
                        <br />
                        TO ABANDON FINANCIAL DISCOMFORT AND KEEP EARNING EVEN IN THE BEAR MARKET
                    </div>

                    <div className="block4">
                        3.
                        <br />
                        TO HEDGE THEMSELVES AND THEIR PORTFOLIO AGAINST MARKET FLUCTUATIONS AND RECESSION
                    </div>

                    <div className="header-text3-mp">
                        EARN CRYPTO REWARDS WITH BEST STAKING PLATFORM
                    </div>
                    <div className="block5">
                        As we navigate the dynamic landscape of cryptocurrencies, an essential concept has made a significant impact: crypto staking.
                        Through a leading staking platform Nexostake, staking and hosting nodes becomes an efficient, secure, and non-custodial process,
                        marking the service as the top crypto staking platform of 2024.
                        <br /> <br />
                        To work with the Nexostake platform, we recommend using the Trust Wallet cryptocurrency wallet.
                        To get started, you will need BNB Smart Chain tokens (listed below) for staking and BNB tokens for paying commissions.
                        <br /><br />
                        Token list:
                        <br />
                        <br />
                        <div className="mc6">


                            0x0d8bC6A8306fFbb83d08EB7ec6F5413b0D75327d
                            <br />
                            <br />


                            0xB0f86Ee7a779aDE83537466d8836f68edAdFf7a9
                            <br />
                            <br />


                            0x1ef909B379AA21B1045070c595bE71d8E6896cee
                            <br />
                            <br />



                            0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47
                            <br />
                            <br />


                            0xCC42724C6683B7E57334c4E856f4c9965ED682bD
                            <br />
                            <br />


                            0x8b1f4432F943c465A973FeDC6d7aa50Fc96f1f65
                            <br />
                            <br />


                            0x0Eb3a705fc54725037CC9e008bDede697f62F335
                            <br />
                            <br />


                            0x31b79e34B32239f0c3604db4a451CE9256B92919

                        </div>
                        <br />

                        Managing assets on Trust Wallet is convenient and safe. Keep seed phrases and private keys in a safe place.
                        Make sure you have enough BNB tokens to pay the fees.
                    </div>

                    <div className="header-text4-mp">
                        UNDERSTANDING CRYPTO STAKING
                    </div>
                    <div className="block6">
                        In the world of proof-of-stake (PoS) blockchains, crypto staking is a practice where participants lock up their cryptocurrency to support network operations, including transaction validation.
                        As a result, they earn rewards, typically more of the same cryptocurrency, paving the way for passive income.
                        <br /><br />
                        Staking and hosting nodes play pivotal roles in the functionality and security of blockchain networks.
                        <br /><br />
                        In the world of cryptocurrency, staking is a method of participating in a proof-of-stake (PoS) blockchain network by locking up a certain amount of the cryptocurrency in a network wallet.
                        Through this process, stakers can help validate transactions on the network and earn staking rewards in return.
                        <br /><br />
                        Staking plays an essential role in blockchain networks, as it incentivizes participants to maintain the network's operation and security.
                        It enables decentralized networks to achieve consensus without needing significant computational power, making them more energy-efficient
                        than their proof-of-work (PoW) counterparts.
                        <br /><br />
                        Hosting nodes, on the other hand, refers to the act of running a computer with specific software that maintains a blockchain network.
                        Nodes validate and propagate transactions across the network, contributing to its overall security and resilience against attacks.
                        <br /><br />
                        Staking and hosting nodes are thus key activities in the world of decentralized finance (DeFi), contributing to network participation
                        and helping users earn digital asset earnings. Whether you're staking Ethereum on Ethereum 2.0 or engaging in Binance staking on the
                        Binance Smart Chain, these processes form the backbone of many popular blockchain protocols.

                    </div>
                    <AccountMasterModal
                        isOpen={accountMasterModalIsOpen}
                        onClose={() => setAccountMasterModalOpen(false)}
                        login={login}
                        data={data}
                    >
                    </AccountMasterModal>

                    <StkUSDTmodal
                        isOpen={stkUSDTmodalIsOpen}
                        onClose={() => setStkUSDTmodalOpen(false)}
                        login={login}
                        apr={filtered[0].USDT}
                        >
                    </StkUSDTmodal>

                    <StkSOLmodal
                        isOpen={stkSOLmodalIsOpen}
                        onClose={() => setStkSOLmodalOpen(false)}
                        login={login}
                        apr={filtered[0].SOLANA}
                        >
                    </StkSOLmodal>

                    <StkETHmodal
                        isOpen={stkETHmodalIsOpen}
                        onClose={() => setStkETHmodalOpen(false)}
                        login={login}
                        apr={filtered[0].ETHEREUM}
                        >
                    </StkETHmodal>

                    <StkEmptymodal
                        isOpen={stkEmptymodalIsOpen}
                        onClose={() => setStkEmptymodalOpen(false)}>
                    </StkEmptymodal>




                    <div className="header-text6-mp">
                        THE IMPORTANCE OF SECURE DEVELOPMENT
                    </div>
                    <div className="block8">
                        Security stands at the forefront of any blockchain development project.
                        The decentralized and immutable nature of blockchain makes it a potentially attractive
                        target for various security threats. Therefore, ensuring the secure development of blockchain platforms and smart contracts is critical.
                        <br />
                        Our staking smart contract is verified and confirmed by company audits Defi and PeckShield.
                        <br />
                        Contract address in FRAX chain:
                        <br />
                        <div className="mc6">
                            0x853d955acef822db058eb8505911ed77f175b99e
                        </div>
                    </div>
                    <div className='c5'>
                        Copyright 2018
                    </div>


                </div>






            </div>

        </div>

    )
} 