import React from 'react'
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi'
import './WalletModal.css';

export function Account() {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { data: ensName } = useEnsName({ address })
  const { data: ensAvatar } = useEnsAvatar({ name: ensName! })

  return (
    <div>
      <div className='wallet-header'>
        Wallet connected
      </div>
      <div className='wallet-address'>
        {address}


        <button className='btn3-1' onClick={() => disconnect()}>Disconnect</button>
      </div>
    </div>
  )
}

export function GetWallet() {
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { data: ensName } = useEnsName({ address })
  const { data: ensAvatar } = useEnsAvatar({ name: ensName! })

  return (
    <div>
      {address}
    </div>
  )
}
