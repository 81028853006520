import React, { useState, useEffect } from 'react';
import { Login } from './components/Login/Login.jsx';
import { MainPage } from './components/MainPage/MainPage.jsx';
import './App.css';

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return { isMobile }
}

function App() {
  const [mainMenuIsOpen, setMainMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1768);

  if (isMobile) {
  return (
    <div className="App">
     
        <Login/>
    
    </div>
  ); }
  return  <div className='WarningScreen'>Please use mobile browser</div>
}

export default App;
